<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.delivery_notes')">
            <CustomTable   ref="deliveryNotesTable" :fields="computedColumnDefs" api-url="/api/client/v1/delivery_notes/">
                <template #buttons>
                    <b-button variant="primary" @click="addDeliveryNote">
                        <feather-icon icon="PlusSquareIcon"/>
                        {{ $t('material.delivery_notes.add_delivery_note') }}
                    </b-button>
                </template>
                <template #state="{item}">
                    <div class="d-flex justify-content-center">
                        <b-badge variant="warning" v-if="item.editing_in_progress && item.confirmed">
                            {{ $t('general.yes') }}
                        </b-badge>
                        <b-badge variant="success" v-else-if="item.confirmed">
                            {{ $t('general.no') }}
                        </b-badge>
                        <b-badge variant="danger" v-else>
                            {{ $t('general.not_included_in_stock') }}
                        </b-badge>
                    </div>
                </template>
                <template #actions="{item}">
                    <div class="d-flex justify-content-center">
                        <b-button class="mr-1 m-0 button" variant="danger" size="sm" @click="remove(item)">
                            {{ $t('general.delete') }}
                        </b-button>
                        <b-button class="mr-1 m-0 button d-flex justify-content-center align-items-center" :variant="item.confirmed?'warning':'success'"  size="sm" style="width: 60px;" @click="editRow(item)">
                            <span v-if="item.confirmed"> {{ $t('general.edit') }}</span>
                            <span v-else> {{ $t('general.continue') }}</span>
                        </b-button>
                        <b-button class="m-0 button" variant="secondary"  size="sm" @click="viewDeliveryNotePrintout(item)" :disabled="!item.confirmed">
                            <span style="white-space: nowrap;">
                                {{ $t('general.view_printout') }}
                            </span>
                        </b-button>
                    </div>
                </template>
            </CustomTable>
            <report-preview-modal ref="deliveryNoteReportModal"/>
        </b-card>
    </b-overlay>
</template>

<script>
    import {BButton, BBadge, BCard, BOverlay  } from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import ReportPreviewModal from '@/views/components/ReportPreviewModal.vue'
    import CustomTable from '@/components/CustomTable.vue'
    import {Formaters} from '@/libs/formaters'

    export default {
        components: {ReportPreviewModal, CustomTable, BBadge, BCard, BOverlay, BButton},
        computed:{
            computedColumnDefs() {
                return [
                    {
                        key: 'code',
                        label: this.$t('table_fields.code'),
                        filter: true,
                        filterKey: 'code.w',
                        filterType: 'input',
                        permissions: true,
                        minWidth: 100
                    },
                    {
                        key: 'timestamp',
                        label: this.$t('table_fields.created'),
                        filter: true,
                        permissions: true,
                        formatter: Formaters.formatDateOnly,
                        filterType: 'date',
                        minWidth: 75
                    },
                    {
                        key: 'supplier_id',
                        label: this.$t('table_fields.supplier'),
                        filter: true,
                        filterType: 'select',
                        filterParamsName: 'name',
                        filterParams: this.suppliers,
                        formatter: this.getSupplierName,
                        reduce: ele => ele.id,
                        permissions: true,
                        minWidth: 100,
                        maxWidth: 250
                    },
                    {
                        key: 'warehouse_id',
                        label: this.$t('table_fields.warehouses'),
                        filter: true,
                        filterType: 'select',
                        filterParamsName: 'name',
                        filterParams: this.warehouses,
                        formatter: this.getWarehouseName,
                        reduce: ele => ele.id,
                        permissions: true,
                        minWidth: 100,
                        maxWidth: 300
                    },
                    {
                        key: 'discount_value_field',
                        label: this.$t('table_fields.discount'),
                        filter: false,
                        formatterItem: this.getDiscountValue,
                        permissions: true,
                        minWidth: 75,
                        maxWidth: 75
                    },
                    {
                        key: 'price_with_discount',
                        label:  this.$t('table_fields.net_price'),
                        filter: true,
                        filterType: 'input',
                        filterKey: 'price_with_discount.w',
                        formatter: Formaters.formatPrice,
                        filterFormatter: Formaters.formatNumberForFilter,
                        permissions: true,
                        minWidth: 100,
                        maxWidth: 100
                    },
                    {
                        key: 'vat_value_tmp',
                        label: this.$t('table_fields.vat'),
                        filter: false,
                        formatterItem: this.getVatValue,
                        permissions: true,
                        minWidth: 75,
                        maxWidth: 75
                    },
                    {
                        key: 'price_with_discount_and_vat',
                        label:  this.$t('table_fields.total_price'),
                        filter: true,
                        filterType: 'input',
                        filterKey: 'price_with_discount_and_vat.w',
                        formatter: Formaters.formatPrice,
                        filterFormatter: Formaters.formatNumberForFilter,
                        permissions: true,
                        minWidth: 100,
                        maxWidth: 100
                    },
                    {
                        key: 'state',
                        label: this.$t('table_fields.changes'),
                        permissions: true,
                        minWidth: 50,
                        maxWidth: 100
                    },
                    {
                        key: 'actions',
                        label: this.$t('table_fields.actions'),
                        permissions: true
                    }
                ]
            }
        },
        data() {
            return {
                suppliers: [],
                warehouses: [],
                showLoader: false,
                loaded: false,
                fileName: '',
                apiUrl: '',
                deliveryNoteId: '',
                deliveryNote: {}
            }
        },
        methods: {
            async addDeliveryNote() {
                try {
                    const response = await this.$http.post('/api/client/v1/delivery_notes_temporary/create/000000000000000000000000')
                    const deliveryNoteTemporary = response.data ?? []

                    await this.$router.push({name: 'edit_delivery_note', params:{id: deliveryNoteTemporary.id}})
                } catch (error) {
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            async viewDeliveryNotePrintout(data) {
                this.fileName =  `Dobavnica_${data.code}.pdf`
                this.apiUrl = `/api/reports/v1/printout/delivery_note/${  data.id  }`
                await this.$refs.deliveryNoteReportModal.show(this.fileName, this.apiUrl)
            },
            async loadData() {
                try {
                    this.showLoader = true

                    const response1 = this.$http.get('/api/client/v1/suppliers/')
                    const response2 = this.$http.get('/api/client/v1/warehouses/')
                    const response = await Promise.all([response1, response2])

                    this.suppliers = response[0].data ?? []
                    this.warehouses = response[1].data ?? []

                    const indexSuppliers = this.computedColumnDefs.findIndex(ele => ele.key === 'supplier_id')
                    const indexWarehouses = this.computedColumnDefs.findIndex(ele => ele.key === 'warehouse_id')

                    if (indexSuppliers >= 0) {
                        this.computedColumnDefs[indexSuppliers].filterParams = this.suppliers
                    }
                    if (indexWarehouses >= 0) {
                        this.computedColumnDefs[indexWarehouses].filterParams = this.warehouses
                    }


                    this.loaded = true
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async editRow(data) {
                try {
                    const response = await this.$http.post(`/api/client/v1/delivery_notes_temporary/create/${data.id}`)
                    const deliveryNoteTemporary = response.data ?? []

                    await this.$router.push({name: 'edit_delivery_note', params:{id: deliveryNoteTemporary.id}})
                } catch (error) {
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            async remove(data) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/delivery_notes/${data.id}`)

                    this.$printSuccess(this.$t('print.success.delete'))

                    if (this.$refs.deliveryNotesTable) {
                        await this.$refs.deliveryNotesTable.reloadData()
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            getSupplierName(value) {
                if (value) {
                    const tmp = this.suppliers.find(x => x.id === value)

                    if (tmp) return tmp.name
                }

                return '/'
            },
            getWarehouseName(value) {
                if (value) {
                    const tmp = this.warehouses.find(x => x.id === value)

                    if (tmp) return tmp.name
                }

                return '/'
            },
            getDiscountValue(item) {
                if (!item) {
                    return ''
                }

                return `${this.$numberRounding(item.price - item.price_with_discount)} €`
            },
            getVatValue(item) {
                if (!item) {
                    return ''
                }
                return `${this.$numberRounding(item.price_with_discount_and_vat - item.price_with_discount)} €`
            }
        },
        mounted() {
            this.loadData()

        }
    }
</script>

<style scoped>
.button {
  padding: 0.4rem;
}
</style>
