<template>
    <b-modal ref="modal" :title="$t('reporting.report')" size="xl" @hide="hide">
        <div class="w-100 mainWrapper mt-5">
            <div v-html="html">

            </div>
        </div>

        <template #modal-footer>
            <div class="w-100 d-flex justify-content-end">
                <div>
                    <b-button variant="primary" @click="downloadPdf" :disabled="loading">
                        {{ $t('reporting.download_pdf') }} <b-spinner v-if="loading" class="ml-1" style="height: 20px; width: 20px;" variant="secondary"></b-spinner>
                    </b-button>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import { BModal, BButton, BSpinner } from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components:{
            BModal,
            BButton,
            BSpinner
        },
        data() {
            return {
                loading: false,
                apiUrl: '',
                fileName: '',
                html: ''
            }
        },
        methods:{
            downloadPdf() {
                this.download('pdf')
            },
            async download(type) {
                try {
                    this.loading = true
                    if (type === 'html') {

                        const response = await this.$http({
                            url: `${this.apiUrl  }/html`,
                            method: 'POST'
                        })

                        this.html = response.data ?? ''

                    } else if (type === 'pdf') {

                        const response = await this.$http({
                            url: `${this.apiUrl  }/pdf`,
                            method: 'POST',
                            responseType: 'blob' // important
                        })

                        const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))

                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('target', '_blank')
                        link.setAttribute('download', this.fileName)
                        document.body.appendChild(link)
                        link.click()

                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.loading = false
                }
            },
            show(filename, apiUrl) {
                this.fileName = filename
                this.apiUrl = apiUrl
                this.download('html')
                this.$refs.modal.show()
            },
            hide() {
                this.html = ''
                this.fileName = ''
                this.apiUrl = ''
                this.$refs.modal.hide()
            }
        }
    }
</script>

<style scoped>

.mainWrapper {
  padding-bottom: 50px;
}

.mainWrapper >>> @page {
  size: A4;
  margin: 0;
}
.mainWrapper >>> @media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}

.mainWrapper >>> .ft0 {
  font: 10pt 'Cambria';
  margin-top: 0;
  margin-bottom: 0;
}

.mainWrapper >>> .ft1 {
  font: 12pt 'Cambria';
  margin-top: 0;
  margin-bottom: 0;
}

.mainWrapper >>> .ft2 {
  font: 14pt 'Cambria';
  margin-top: 0;
  margin-bottom: 0;
}

.mainWrapper >>> .ft3 {
  font: 16pt 'Cambria';
  margin-top: 0;
  margin-bottom: 0;
}

.mainWrapper >>> .ft4 {
  font: 18pt 'Cambria';
  margin-top: 0;
  margin-bottom: 0;
}

.mainWrapper >>> .ft5 {
  font: 22pt 'Cambria';
  margin-top: 0;
  margin-bottom: 0;
}

.mainWrapper >>> .ft6 {
  font: 25pt 'Cambria';
  margin-top: 0;
  margin-bottom: 0;
}

.mainWrapper >>> .bold {
  font-weight: bold;
}

.mainWrapper >>> .left {
  text-align: left;
}

.mainWrapper >>> .right {
  text-align: right;
}

.mainWrapper >>> .center {
  text-align: center;
}

.mainWrapper >>> table{
  width: 100%;
  border-collapse: collapse;
  font: 12pt 'Cambria';
}

.mainWrapper >>> th{
  border: none;
  text-align: left;
  color: #575757;
  background-color: #b4bfb5;
}

.mainWrapper >>> td{
  /*border-top: solid 1px gray;*/
  border-bottom: solid 1px gray;
}

.mainWrapper >>> tr td:last-child{
  text-align: right;
}

.mainWrapper >>> tr th:last-child{
  text-align: right;
}

.mainWrapper >>> .container{
  display: flex;
}

.mainWrapper >>> .fixed{
  width: 50%;
}
.mainWrapper >>> .flex-item{
  flex-grow: 1;
}
</style>
